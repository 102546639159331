<template>
  <main id="content" class="main-content page-correction pt-0" role="main">
    <div class="page-correction__header mb-4">
      <div class="page-correction__header--topbar">
        <div class="container-fluid">
          <div class="row align-items-md-center">
            <div class="col-md-4">
              <ul class="list-inline">
                <li class="list-inline-item mr-3">
                  <a href="" @click.prevent="voltar()" class="link fs-24">
                    <i class="fas fa-chevron-left mr-3" />
                  </a>
                </li>
              </ul>
            </div>
            <div class="col-md-4 text-center">
              <h1 class="fs-14 color-oct">{{ prova.titulo }}</h1>
            </div>

            <div class="col-md-4 text-right">
              <a href="#" class="link link-sec">Imprimir</a>
            </div>
          </div>
        </div>
      </div>

      <div class="page-correction__header--details">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6">
              <h1 class="fs-24 color-oct m-0">{{ aluno.nome }}</h1>
            </div>
            <div class="col-md-6 text-right">
              <ul class="list-inline align-items-center m-0">
                <li class="list-inline-item">
                  <div class="badge badge-note" style="color: black">
                    Nota: <span style="color: black">{{ nota }}</span>
                  </div>
                </li>
                <li class="list-inline-item color-oct">
                  {{ disciplina.text }} - {{ anoEnsino.titulo }}
                  {{ turma.titulo }} | {{ prova.dataHoraInicio | formatDate }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="page-correction__content px-5">
      <h3 class="fs-24 mb-4">Prova</h3>

      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="media media-correction ml-5">
              <div class="media-body">
                <div class="card">
                  <div class="page-content__charts p-4 mb-4">
                    <div class="row justify-content-center">

                      <div class="col-md-4 col-xl-3">
                        <div class="block__pie">
                          <p class="title">
                            Questões
                          </p>
                          <div class="app-chart">
                            <pie-chart
                              :data="chartData"
                              :options="chartOptions"
                              ref="provaChart"
                            ></pie-chart>
                          </div>

                          <ul class="list-unstyled text-center">
                            <li>
                              <i class="success"></i>Corretas:
                              <strong> {{ corretasPercent }}%</strong>
                            </li>
                            <li>
                              <i class="error"></i>Erradas:
                              <strong> {{ erradasPercent }}%</strong>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="media media-correction"
              v-for="(resposta, index) in respostas"
              :key="resposta.id"
            >
              <span class="counter mr-1">{{ index + 1 }}</span>

              <div class="media-body">
                <div class="card">
                  <div class="card-header">
                    <strong
                      >Pontos obtidos:
                      {{ resposta.notaAlcancada }} pontos</strong
                    >
                    | Valor da Questão: {{ resposta.notaMaxima }} ponto
                  </div>
                  <div class="card-body">
                    <h5>
                      Enunciado
                      <span
                        class="icon-tooltip"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                        ><i class="help"></i
                      ></span>
                    </h5>

                    <p v-html="resposta.enunciado"></p>
                  </div>

                  <div class="card-footer">
                    <h5 class="mb-3">
                      {{ resposta.tipo === 'Descritiva' ? 'Resposta' : 'Respostas' }}
                      <span
                        class="icon-tooltip"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                        ><i class="help"></i
                      ></span>
                    </h5>

                    <p v-if="resposta.tipo === 'Descritiva'" v-html="resposta.resposta"></p>

                    <ul
                      v-else
                      class="list-answers list-unstyled"
                      v-for="alternativa in resposta.alternativas"
                      :key="alternativa.id"
                    >
                      <li class="media success" v-if="alternativa.is_correta">
                        <span class="icon" v-if="alternativa.checked">
                          <i class="fas fa-check"></i>
                        </span>

                        <span class="media-body">
                          <span
                            class="alert alert-success"
                            role="alert"
                            v-if="alternativa.checked"
                            v-html="alternativa.text"
                          >
                          </span>
                          <span class="alert alert-success alert-success-claro" role="alert" v-html="alternativa.text" v-else>
                          </span>
                        </span>
                      </li>

                      <li class="media error" v-else>
                        <span class="icon" v-if="alternativa.checked">
                          <i class="fas fa-times"></i>
                        </span>

                        <span class="media-body">
                          <span
                            class="alert alert-danger"
                            role="alert"
                            v-if="alternativa.checked"
                            v-html="alternativa.text"
                          >
                          </span>
                          <span class="alert alert-default" role="alert"
                            v-html="alternativa.text" v-else>
                          </span>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import moment from "moment";
import PieChart from "./PieChart.js";

export default {
  components: {
    PieChart,
  },
  data() {
    return {
      showLeftMenu: true,
      prova: {},
      aluno: {},
      disciplina: {},
      anoEnsino: {},
      turma: {},
      respostas: [],
      nota: 0,
      mediaTurma: 0,
      corretas: 0,
      corretasPercent: 0,
      parciais: 0,
      erradas: 0,
      erradasPercent: 0,
      chartOptions: {
        hoverBorderWidth: 20,
        renderOnMount: false
      },
      chartData: null,
    };
  },
  computed: {
  },
  created() {
    this.loadProva();
    this.loadAluno();
    this.loadRespostas();
    this.loadMediaTurma();
  },
  filters: {
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY - hh:mm");
      } else {
        return "-";
      }
    },
  },
  methods: {
    voltar() {
      this.$router.go(-1);
    },
    loadProva() {
      this.$api
        .get("provas/" + this.$route.params.idprova)
        .then((response) => {
          this.prova = response.data;
          this.disciplina = this.prova.disciplina;
          this.anoEnsino = this.prova.turma.anoEnsino;
          this.turma = this.prova.turma;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    loadAluno() {
      this.$api
        .get("alunos/" + this.$route.params.idaluno)
        .then((response) => {
          this.aluno = response.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    loadMediaTurma() {
      this.$api
        .get("/estatistica-prova/" + this.$route.params.idprova)
        .then((response) => {
          this.mediaTurma = response.data.media;
        });
    },
    loadRespostas() {
      this.$api
        .get(
          "resultado-prova-aluno/" +
            this.$route.params.idprova +
            "/" +
            this.$route.params.idaluno
        )
        .then((response) => {
          this.respostas = response.data.respostas;
          console.log(this.respostas);
          this.respostas.map(function(entry) {
            entry.alternativas = JSON.parse(entry.alternativas);
          });

          this.respostas.forEach((element) => {
            if (element.isCorreta) {
              this.corretas++;
              this.nota = this.nota + element.notaAlcancada;
            } else {
              this.erradas++;
            }
            if (
              element.notaAlcancada > 0 &&
              element.notaAlcancada < element.notaMaxima
            ) {
              this.parciais++;
            }
          });

          this.corretasPercent = (
            (100 / this.respostas.length) *
            this.corretas
          ).toFixed(2);
          this.erradasPercent = ((100 / this.respostas.length) * this.erradas).toFixed(
            2
          );

          this.chartData = {
            hoverBackgroundColor: "red",
            hoverBorderWidth: 10,
            labels: ["Corretas", "Erradas"],
            datasets: [
              {
                backgroundColor: ["#41B883", "#E46651"],
                data: [
                  this.corretas,
                  this.erradas,
                ],
                label: 'Teste'
              },
            ],
          };

          this.$refs.provaChart.renderCustom(this.chartData);
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
  },
};
</script>

<style>
.app-chart {
  width: 275px;
  height: 275px;
}
</style>
